:root {
    --doc-height: 100%;
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Regular.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Italic.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Thin-BETA.woff) format('woff');
    font-display: swap;

}


@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Light-BETA.woff) format('woff');
    font-display: swap;

}


@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Medium.woff) format('woff');
    font-display: swap;

}


@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-SemiBold.woff) format('woff');
    font-display: swap;

}


@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Bold.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: "Recoleta";
    src: local("Recoleta"),
    url("themes/fonts/634ab12299023664817c1ec0_Recoleta-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Recoleta";
    src: local("Recoleta"),
    url("./themes/fonts/6349ec910007a158ad11acf1_Recoleta-Bold.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Futura Std";
    src: local("Futura Std"),
    url("./themes/fonts/FuturaStd-Bold.woff2") format("woff2");
    font-weight: 700;
    font-display: swap;

}


html,
body {
    background: black;
    padding: 0;
    margin: 0;
    height: 100vh; /* fallback for Js load */
    height: var(--doc-height);
}

.App {
    text-align: center;
}



.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.mainContainer {
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
}

.RestaurantIntroContainer {
    width: 100vh;
    height: 100vh;
    padding: 0;
}

.DishCatalogSliderContainer {
    width: 100vh;
    height: var(--doc-height);
    background: black;
    padding: 0 !important;
    margin: 0 !important;


}

.RestaurantCatalogListContainer {
    width: 100vh;
    height: 100%;
    background: black;
    padding: 0 !important;
    margin: 0 !important;
}

.MaxInnerHeight{
    height: var(--doc-height);
}



.swiper {
    max-width: 100%;
    width: 100vh;
    height: 100%;
    z-index: 1000 !important;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

span {
    color: white;
    font-family: "Helvetica Neue", sans-serif;
}

.HeadingRegularNormal {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Futura Std', sans-serif;
    text-transform: uppercase;

}

.HeadingRegularBold {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Futura Std', sans-serif;
    text-transform: uppercase;
}


.HeadingSmallNormal {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Futura Std', sans-serif;
    text-transform: uppercase;

}

.HeadingSmallBold {
    font-size: 13px;
    font-weight: 700;
    font-family: 'Futura Std', sans-serif;
    text-transform: uppercase;


}

.HeadingXSmallBold {
    font-size: 11px;
    font-weight: 700;
    font-family: 'Futura Std', sans-serif;
    text-transform: uppercase;

}

.TextSmallRegular {
    font-size: 11px;
    font-weight: 400;
}

.TextSmallBold {
    font-size: 11px;
    font-weight: 700;
}

.TextRegularNormal {
    font-size: 13px;
    font-weight: 400;
}

.TextRegularBold {
    font-size: 13px;
    font-weight: 700;
}

.TextMediumNormal {
    font-size: 15px;
    font-weight: 400;
}

.TextMediumBold {
    font-size: 15px;
    font-weight: 700;
}

.TextCenter {
    align-items: center;
    display: inline-flex;
}

.dish {
    aspect-ratio: 3/4;

    width: 100%;
    background-size: cover;
    display: flex;
    align-items: end;
    padding: 11px;
}

.dishFullView {
    height: var(--doc-height);
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 2000;

}

.menuHeader {
    position: fixed;
    z-index: 10000;
    top: 0;
    background: black;
}

.HeaderCategoryDish {
    z-index: 9000;
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    position: sticky;
    top: 0; /* Cambia este valor si tienes una barra de navegación en la parte superior */

}

.videoSlide {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;

}

.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.7805322812718838) 0%, rgba(0,0,0,0) 40%);
    z-index: 1500;
    pointer-events: none;
}


@keyframes miAnimacion {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.animada {
    animation: miAnimacion 2s ease-in-out;
}


.categoryMenuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    max-width: 16.6666666667%;
    min-width: 16.6666666667%;
    height: 100%;
}


.CategoryMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    color: white;
    font-family: "Recoleta";
    background: black;
    font-size: 18px;
    font-weight: 500;
    z-index: 10000;
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow-x: scroll;
}

.MenuHeaderGrid {
    position: fixed;
    background: black;
    height: 60px;
    transition-timing-function: ease-in;
    transition: 0.5s;
    width: 100%;
}

.buttonDisplayMenu{
    position: fixed;
    top: 10px;
    height: 50px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-timing-function: ease-in-out;
    transition: 0.5s;
    border-radius: 22px;
    right: 10px;
    background: black;
}

.MenuHeaderGridSecondGround {
    height: 60px;
}

.dishFullViewContent {
    position: absolute;
    padding: 20px 5px;
    z-index: 2000;
    pointer-events: none;

}

.restaurantLogo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.restaurantBanner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.brandLogo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.catalogPhoto{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@keyframes shakeUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    50% {
        transform: translateY(-50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}



.flyingBtn {
    z-index: 10000;
    transform-origin: center bottom;
    transform: scale(1);
    background-image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAeCAYAAADQBxWhAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHeSURBVHgB7ZZNKGxhGMef0V3d273XrVtWBhsfJU0oCx9ZW1splCykROyUz2wsWcnKQlkiNnZE2WClKDIon+VjgRWO39s8U2OcjznOZKH516+3Oc/XeZ/znueMSEbfTSHxKcuywiwR+AOXcBwKhQ4l3aJQNgzCvmWvA+iDPEmHSNQCd/AKy9AMlVAItdAIc1o8Ch0SRCQY0mQrUODhmw9L6j8knyzYqwmGfcaNaFyPn7j4XT/DpItPxMU2DY9QKj6KzujzCTvY63U3EQe7OXg3sCgpFszVhN0uPvGi9S4+/erzP9mWZeNfo+uSBNOsrg2SQtEKuOCFP5IAIv7U5NF87/TDxt+0I5p4gRa1sQzY+M5ju0/4PU6xqYTf5xKbXJ5FLfibdG0bEg+FmTz5sCKxURjXhk0ub5n30tw9ZLn4eB4k9XuA0eTrdonXJLbTWgkgilWz/IL1VIrugHlOTRJM7XAmH1tuL22xUZWDPQy7ZnI52As0fkxSlU6UKBxBjvgQ/v801pDtJ9YEl+kn7dBpRzYxZmbvwTUUy2dkBjacKF3w08Ev/pG/hSsod8vr+XdFdzkMr";
    border-radius: 24px;
}


@keyframes scale-display {
    0% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.button--disapear {
    display: none;

    &.active {
        animation: scale-display .3s;
        display: inline-flex;
    }

    &.out {
        animation: scale-display--reversed .3s;
        animation-fill-mode:forwards;
        display: inline-flex;
    }
}

@keyframes scale-display--reversed {
    0% {
        display: inline-flex;
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    99% {
        display: inline-flex;
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    100% {
        display: none;
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }
}

